import React, { Component } from 'react';
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import UserApi from '../api/users.api';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

export default class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            page: 1,
            totalCount: 10,
            searchText: '',
            isSuperAdmin: JSON.parse(Cookies.get('isSuperAdmin')),
            currentUserId: 0,
            currentUser: '',
            modalIsOpen: false,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.export = this.export.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    setCurrentUser(userId, currentUser) {
        this.setState({
            currentUserId: userId,
            currentUser,
            modalIsOpen: true,
        });
    }
    async getUsers(pageNumber) {
        try {
            const result = await UserApi.getAll(
                pageNumber ? pageNumber : this.state.page,
                this.state.totalCount,
                this.state.searchText
            );
            console.log(result.users.rows)
            this.setState({
                users: result.users.rows,
                page: pageNumber ? pageNumber : this.state.page
            });
        } catch (error) {
            console.log(error);
        }
    }


    async deleteUser() {
        try {
            const result = await UserApi.deleteUser(this.state.currentUserId);
            console.log(result);
            if (result.status == 204) {
                this.setState({
                    currentUser: '',
                    currentUserId: 0,
                    modalIsOpen: false,
                });
                toast.success('Корисникот беше усшешно избришан');
                this.getUsers();
            }
        } catch (error) {
            console.log(error);
            toast.error('Грешка при бришење');
        }
    }
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }
    setIsOpen(value) {
        this.setState({ modalIsOpen: value });
    }
    async componentDidMount() {
        await this.getUsers();
    }

    async handlePageClick(e) {
        await this.getUsers(e.selected + 1);
    }
    export() {
        return <Link to='/export'></Link>;
    }

    openModal() {
        this.setIsOpen(true);
    }
    closeModal() {
        this.setIsOpen(false);
        this.setState({ passwordError: '' });
    }

    render() {
        Modal.setAppElement('#root');
        const customStyles = {
            content: {
                top: '30%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
            },
        };
        return (
            <Row className='table-section'>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel='Example Modal'
                    style={customStyles}
                >
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label className='black'>
                                Дали сте сигурни дека сакате да го избришите корисникот{' '}
                                {this.state.currentUser} ?
                            </Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId='formPlaintextPassword'>
                            <Button
                                onClick={this.deleteUser}
                                className='mr-2 btn btn-success'
                            >
                                Да
                            </Button>
                            <Button onClick={this.closeModal} className='btn btn-danger'>
                                Откажи
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal>
                <Col lg={12}>
                    <figure className='icon-group'>
                        {/* <img src={OneTimeIcon} alt='One-Time Check Icon' /> */}
                        <h2 className='heading ml-3'>Клиенти</h2>
                    </figure>
                    <Form inline onSubmit={this.handleSubmit}>
                        <FormControl
                            type='text'
                            placeholder='Пребарај клиент'
                            className='mr-sm-2'
                            onChange={this.handleChange}
                            name='searchText'
                        />
                        <Button
                            className='btn mr-2 button-standard'
                            onClick={() => this.getUsers()}
                        >
                            <i className='material-icons'>search</i>
                            Пребарај
                        </Button>
                        {/* <Download /> */}
                    </Form>
                    <section className='list-box mt-3'>
                        <Table responsive='xl' className='mb-5'>
                            <thead className='table-head'>
                                <tr>
                                    <th>Име на клиент</th>
                                    <th>Tелефонски број</th>
                                    <th>Референтен број</th>
                                    <th>Регистриран на</th>
                                    {this.state.isSuperAdmin ? <th>Акција</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users?.length > 0 ? (
                                    this.state.users.map((i) => (
                                        <tr key={i.viber_id}>
                                            <td>{i.full_name}</td>
                                            <td>{i.user_phone}</td>
                                            <td>{i.uniqueNumber}</td>
                                            <td>{i.date_of_registration}</td>
                                            {this.state.isSuperAdmin ? (
                                                <td>
                                                    <Button
                                                        onClick={() =>
                                                            this.setCurrentUser(i._id, i.full_name)
                                                        }
                                                        className='btn btn-danger'
                                                    >
                                                        <i className='material-icons'>delete</i>
                                                        Избриши
                                                    </Button>
                                                </td>
                                            ) : null}
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">Нема корисници за прикажување.</td>
                                    </tr>
                                )}


                            </tbody>
                        </Table>
                        <ReactPaginate
                            previousLabel={'< Претходна'}
                            nextLabel={'Следна >'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={
                                this.state.users && this.state.users.count
                                    ? Math.ceil(this.state.users.count / 10)
                                    : 1
                            }
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'list-group-pagination pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            disabledClassName={'page-item disabled'}
                            activeClassName={'page-item active'}
                        />
                    </section>
                </Col>
            </Row>
        );
    }
}