import Axios from "axios";
import Cookies from "js-cookie";

class UserApi {

  async uploadNumbers(data) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };


    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/uploads/upload-numbers`;

      const res = await Axios.post(url, data, configAPi);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async getAll(pageNumber, totalCount, searchText) {
    const token = Cookies.get('jwt');
    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/all`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async getAllAdmins(pageNumber, totalCount, searchText) {
    const token = Cookies.get('jwt');
    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/users/admins`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/admins`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async editAdmin(adminId, userName, password) {
    try {
      const token = Cookies.get('jwt');
      const configAPi = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/users/edit-admin`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/edit-admin`,
        { adminId, userName, password },
        configAPi
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(data) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };


    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/users/delete/${data}`;

      const res = await Axios.delete(url, configAPi);
      return res;
    } catch (err) {
      throw err;
    }
  }

}

export default new UserApi();
