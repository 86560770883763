import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import conversationsApi from "../api/conversations.api";
import "../css/ChatBox.css";
import Cookies from "js-cookie";


const ChatBox = () => {
  const { conversationId } = useParams(); // Get conversationId from URL
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [currentClient, setCurrentClient] = useState("");
  const messagesEndRef = useRef(null);
  const socketRef = useRef(null); // ✅ Define socketRef properly

  const buttonRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); 
      buttonRef.current?.click();
    }
  };

  const handleClick = () => {
    console.log("Button clicked!");
  };

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_API_BASE_URL;
    console.log("🔗 Connecting to WebSocket at:", socketUrl);

    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        transports: ["websocket"],
        reconnection: true,
        query: { conversationId },
      });

      socketRef.current.on("connect", () => {
        console.log("✅ Connected to WebSocket with conversationId:", conversationId);
        console.log("📌 WebSocket ID:", socketRef.current.id); // Log socket ID

        // Emit join event with conversationId
        socketRef.current.emit("join_conversation", { conversationId });

        // Update the socket ID to conversationId for tracking
        socketRef.current.id = conversationId;
      });

      socketRef.current.on("receive_message", (data) => {
        console.log("📩 Received message:", data);
        if (data.conversationId === conversationId) {
          setMessages((prev) => [...prev, data.message]);
        }
      });

      socketRef.current.on("disconnect", () => {
        console.warn("❌ WebSocket disconnected!");
      });

      socketRef.current.on("connect_error", (err) => {
        console.error("⚠ WebSocket Connection Error:", err);
      });
    }


    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [conversationId]);

  useEffect(() => {
    if (socketRef.current) {
      console.log(`🔄 Forcing rejoin to room ${conversationId}`);
      socketRef.current.emit("join_conversation", { conversationId });
    }
  }, [conversationId]);


  // ✅ Fetch Existing Messages When Conversation Changes
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await conversationsApi.getConversationById(conversationId);
        if (response) {
          setCurrentClient(response.response.name);
          setMessages(response.response.messages || []);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [conversationId]);

  // ✅ Scroll to Bottom When Messages Update
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // ✅ Send Message
  const sendMessage = () => {
    if (!newMessage.trim() || !socketRef.current) return;

    console.log(Cookies.get('currentUser'))

    const msg = {
      sender: 2,
      text: newMessage,
      created: new Date().toISOString(),
      admin_name: Cookies.get('currentUser'),
      isAnswer: true,
    };

    socketRef.current.emit("send_message", { conversationId, message: msg });
    //setMessages((prev) => [...prev, msg]);

    setNewMessage("");
  };

  return (
    <div className="chat-container">
      <div className="chat-box">
        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.sender === 1 ? "user-msg" : "admin-msg"}`}>
            <div className="message-header">
              <strong>{msg.sender === 1 ? currentClient : msg.admin || "Admin"}</strong>
            </div>
            <div className="message-content">{msg.text}</div>
            <div className="message-time">{new Date(msg.created).toLocaleTimeString()}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Напиши одговор..."
          onKeyDown={handleKeyDown}

        />
        <button onClick={sendMessage} ref={buttonRef}>Испрати</button>
      </div>
    </div>
  );
};

export default ChatBox;
