import React, { Component } from 'react';
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import conversationsApi from '../api/conversations.api';
import '../css/messages.css';

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalCount: 10,
      searchText: '',
      messages: { conversations: [], totalCount: 0 },
    };

    this.getMessages = this.getMessages.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  async componentDidMount() {
    await this.getMessages(this.state.page);
  }

  async getMessages(pageNumber = 1) {
    try {
      const result = await conversationsApi.getAllConversations(
        pageNumber,
        this.state.totalCount,
        this.state.searchText
      );

      if (result.response && result.response.conversations) {
        this.setState({
          messages: result.response,
          page: pageNumber,
        });
      } else {
        toast.error('Невалиден одговор од серверот');
      }
    } catch (error) {
      console.error(error);
      toast.error('Грешка при превземањето на пораките');
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ page: 1 }, async () => {
      await this.getMessages(1); // Reset to page 1 on search
    });
  };

  async handlePageClick(e) {
    await this.getMessages(e.selected + 1);
  }

  render() {
    const { conversations, totalCount } = this.state.messages;

    return (
      <Row className="messages-container">
        <ToastContainer />

        {/* Search Form */}
        <Form inline onSubmit={this.handleSubmit} className="mb-5">
          <Form.Group controlId="searchText" className="d-flex align-items-center">
            <FormControl
              type="text"
              placeholder="Пребарај разговор"
              className="me-3"
              name="searchText"
              value={this.state.searchText}
              onChange={this.handleChange}
              style={{ width: '250px' }}
            />
            <Button className="btn button-standard" type="submit">
              <i className="material-icons">search</i>
              Пребарај
            </Button>
          </Form.Group>
        </Form>

        <Col lg={12}>
          <h2 className="heading">Коментари / Прашања</h2>

          <section className="list-box">
            <Table responsive="xl" className="messages-table">
              <thead>
                <tr>
                  <th>Име на испраќач</th>
                  <th>Тел. Број на испраќач</th>
                  <th>Последна порака</th>
                  <th>Дата</th>
                  <th>Акција</th>
                </tr>
              </thead>
              <tbody>
                {conversations.length > 0 ? (
                  conversations.map((conversation) => (
                    <tr key={conversation._id}>
                      <td>{conversation.profile.name}</td>
                      <td>{conversation.phone}</td>
                      <td>
                        {conversation.last_message}{' '}
                        {!conversation.isAnswer && (
                          <span style={{ color: 'red', fontWeight: 'bold' }}>❗❗❗</span>
                        )}
                      </td>
                      <td>{new Date(conversation.last_sent).toLocaleString()}</td>
                      <td>
                        <Link className="btn btn-action" to={`/messages/${conversation._id}`}>
                          🔍 Прегледај одговори
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">Нема пораки</td>
                  </tr>
                )}
              </tbody>
            </Table>

            {/* Pagination */}
            <ReactPaginate
              previousLabel={'⬅ Претходна'}
              nextLabel={'Следна ➡'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalCount / this.state.totalCount)}
              forcePage={this.state.page - 1}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </section>
        </Col>
      </Row>
    );
  }
}
