import Axios from "axios";
import Cookies from "js-cookie";

class ConversationsAPI {
    async getAllConversations(pageNumber, totalCount, searchText) {
        try {
            const token = Cookies.get('jwt');
            const configAPi = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };

            const r = await Axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/conversations/all`,
                {
                    pageNumber,
                    totalCount,
                    searchText: searchText || '' 
                },
                configAPi
            );

            return r.data;
        } catch (error) {
            throw error;
        }
    }


    async getConversationById(conversationId) {
        try {
            const token = Cookies.get('jwt');
            const configAPi = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            const r = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/conversations/${conversationId}`,
                configAPi
            );
            return r.data;
        } catch (error) {
            throw error;
        }
    }

    async sendMessage(conversationId, answer) {
        try {
            const token = Cookies.get('jwt');
            const admin_name = Cookies.get('currentUser');
            const configAPi = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            const r = await Axios.post(
                `${process.env.REACT_APP_LOCAL_API_URL}/conversations/send-answer`,
                {
                    conversationId, admin_name, answer: answer.text
                },
                configAPi
            );
            return r.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new ConversationsAPI();