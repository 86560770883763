import Axios from "axios";
import Cookies from "js-cookie";
class Messages {


  async sendBulk(req) {
    try {
      const token = Cookies.get("jwt");
      const configAPI = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const url = `${process.env.REACT_APP_API_BASE_URL}/bulk/viber`;

      const response = await Axios.post(url, req, configAPI);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAllMessages(pageNumber, totalCount, searchText) {
    try {
      const token = Cookies.get('jwt');
      const configAPi = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/messages/all`
          : `${process.env.REACT_APP_LOCAL_API_URL}/messages/all`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

  async sendAnswer(messageId, answer, answeredBy) {
    try {
      const token = Cookies.get('jwt');
      const configAPi = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/messages/send-answer`
          : `${process.env.REACT_APP_LOCAL_API_URL}/messages/send-answer`,
        { messageId, answer, answeredBy },
        configAPi
      );
      return r;
    } catch (error) {
      throw error;
    }
  }

  async sendAnswerWithImage(messageId, file, text, answeredBy) {
    try {
      const token = Cookies.get('jwt');
      const configAPi = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const data = new FormData();
      data.append('file', file);
      data.append('messageId', messageId);
      data.append('answeredBy', answeredBy);
      data.append('text', text);
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/messages/send-answer-with-image`
          : `${process.env.REACT_APP_LOCAL_API_URL}/messages/send-answer-with-image`,
        data,
        configAPi
      );
      return r;
    } catch (error) {
      throw error;
    }
  }

  async getMessage(messageId) {
    try {
      const token = Cookies.get('jwt');
      const configAPi = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL}/messages/get-message`
          : `${process.env.REACT_APP_LOCAL_API_URL}/messages/get-message`,
        { messageId },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

}

export default new Messages();
